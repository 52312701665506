import Logo from "../../../assets/logo.svg";
import LogoBlanco from "../../../assets/logoBlanco.svg";
import { IconBurger } from "./iconBurger.component";
import styles from "./HeaderBurguer.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useMemo } from "react";

export const HeaderBurguer = () => {
  let navigate = useNavigate();

  const location = useLocation();
  const currentRoute = location.pathname;

  const headerStyle = useMemo(() => {
    if (currentRoute === "/") {
      return styles.containerBlack;
    }
    if (currentRoute === "/servicios") {
      return styles.container;
    } else {
      return styles.containerBlack;
    }
  }, [currentRoute]);

  return (
    <header className={headerStyle}>
      <img
        style={{
          visibility: currentRoute === "/" ? "hidden" : "visible",
        }}
        alt="logo"
        className={styles.logo}
        src={headerStyle == styles.containerBlack ? LogoBlanco : Logo}
        onClick={() => navigate("/")}
      />
      <IconBurger />
    </header>
  );
};
