import { FC, useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./NavLinks.module.css";

type Prop = {
  isFooter?: boolean;
};
const NavLinks: FC<Prop> = () => {
  const location = useLocation();
  const currentRoute = location.pathname;

  const navStyle = useMemo(() => {
    if (currentRoute === "/") {
      return styles.containerBlack;
    }
    if (currentRoute === "/servicios") {
      return styles.containerGrey;
    } else {
      return styles.containerBlack;
    }
  }, [currentRoute]);

  return (
    <nav className={navStyle}>
      <ul>
        <li className={currentRoute === "/" ? styles.active : ""}>
          <NavLink to="/">INICIO</NavLink>
        </li>
        <li className={currentRoute === "/servicios" ? styles.active : ""}>
          <NavLink to="/servicios">SERVICIOS</NavLink>
        </li>
        <li className={currentRoute === "/contacto" ? styles.active : ""}>
          <NavLink to="/contacto">CONTACTO</NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavLinks;
