import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./SlideImages.css";

// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getSlidersApi } from "../../store/slices/sliders.slice";
import { useResize } from "../hooks/useResize";

import { Stack } from "@mui/material";

const SlideImages = () => {
  const isMobile = useResize();
  const sliders = useAppSelector((state) => state.sliders.sliders);
  const dispatch = useAppDispatch();

  // const sliders = {
  //   mobile: [
  //     { image: { url: "/home/joaquin/Descargas/Equipo_Fondo Azul.jpg" } },
  //     { image: { url: "/home/joaquin/Descargas/Áreas de Práctica_Fondo.jpg" } },
  //   ],
  //   tablet: [
  //     { image: { url: "/home/joaquin/Descargas/Equipo_Fondo Azul.jpg" } },
  //     { image: { url: "/home/joaquin/Descargas/Áreas de Práctica_Fondo.jpg" } },
  //   ],
  //   desktop: [
  //     {
  //       image: {
  //         url: "https://media.istockphoto.com/id/1662723451/es/foto/vista-a%C3%A9rea-abstracta-de-la-miner%C3%ADa-de-sal-y-el-avi%C3%B3n-peque%C3%B1o.jpg?s=1024x1024&w=is&k=20&c=UYzn4sNLHBOyy21cykZHWDHjYL7aew6v98LY3xYnSl8=",
  //       },
  //     },
  //     {
  //       image: {
  //         url: "https://estaticos-cdn.prensaiberica.es/clip/823f515c-8143-4044-8f13-85ea1ef58f3a_16-9-discover-aspect-ratio_default_0.webp",
  //       },
  //     },
  //   ],
  // };

  useEffect(() => {
    dispatch(getSlidersApi());
  }, [dispatch]);
  if (
    !sliders.mobile ||
    !sliders.tablet ||
    !sliders.desktop ||
    sliders?.mobile.length < 0 ||
    sliders?.tablet.length < 0 ||
    sliders?.desktop.length < 0
  ) {
    return <></>;
  }

  return (
    <Stack width="100%" height="fit-content">
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        loop={true}
        modules={[Autoplay, EffectFade, Navigation, Pagination]}
        className="mySwiper"
      >
        {isMobile < 765 &&
          sliders.mobile.map((image, index) => {
            return (
              <SwiperSlide key={index}>
                <img src={`${image.image.url}`} alt="imagen" />
              </SwiperSlide>
            );
          })}
        {isMobile >= 765 &&
          isMobile < 1200 &&
          sliders.tablet.map((image, index) => {
            return (
              <SwiperSlide key={index}>
                <img src={`${image.image.url}`} alt="imagen" />
              </SwiperSlide>
            );
          })}
        {isMobile > 1201 &&
          sliders.desktop.map((image, index) => {
            return (
              <SwiperSlide key={index}>
                <img src={`${image.image.url}`} alt="imagen" />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </Stack>
  );
};

export default SlideImages;
