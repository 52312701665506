import { NavLink, useLocation } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import useToggle from "../../hooks/useToggle";
import styles from "./iconBurguer.module.css";
import { useMemo } from "react";

export const IconBurger = () => {
  const { isSelected, toggle } = useToggle();
  const handleBurguerIcon = () => {
    toggle();
  };

  const location = useLocation();
  const currentRoute = location.pathname;

  const data = useAppSelector((state) => state.data.data);

  const burguerStyle = useMemo(() => {
    if (currentRoute === "/") {
      return styles.hamburgerMenuBlack;
    }
    if (currentRoute === "/servicios") {
      return styles.hamburgerMenu;
    } else {
      return styles.hamburgerMenuBlack;
    }
  }, [currentRoute]);

  return (
    <div className={burguerStyle} onClick={handleBurguerIcon}>
      <div
        className={isSelected ? `${styles.bar} ${styles.animate}` : styles.bar}
      >
        {isSelected && (
          <nav
            className={
              isSelected
                ? `${styles.container} ${styles.open}`
                : styles.container
            }
          >
            <ul className={styles.dropdown}>
              <li className={styles.dropdownItem1}>
                <NavLink to="/">INICIO</NavLink>
              </li>
              <li className={styles.dropdownItem3}>
                <NavLink to="/servicios">SERVICIOS</NavLink>
              </li>
              <li className={styles.dropdownItem5}>
                <NavLink to="/contacto">CONTACTO</NavLink>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
};
