import styles from "./MainTitle.module.css";

interface MainTitleProps {
  title: string;
  align?: "right" | "left";
  color?:
    | "blackRight"
    | "blackLeft"
    | "whiteRight"
    | "whiteLeft"
    | "yellowRight"
    | "yellowLeft";
  strong?: string;
}

const MainTitle = ({ title, color, strong }: MainTitleProps) => {
  return (
    <div className={color ? styles[color] : styles.blackLeft}>
      <p className={styles.title}>
        <>
          {title}
          {strong && <span className={styles.strong}>{strong}</span>}
        </>
      </p>
    </div>
  );
};

export default MainTitle;
