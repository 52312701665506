import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./SlideImagesServices.css";

import image1 from "../../assets/services/2.jpg";
import image2 from "../../assets/services/3.jpg";
import image3 from "../../assets/services/4.jpg";

// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { useResize } from "../hooks/useResize";

import { Box, Stack } from "@mui/material";

const sliders = [image1, image2, image3];

const SlideImages = () => {
  return (
    <Stack width="100%" height="fit-content">
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        loop={true}
        modules={[Autoplay, EffectFade, Navigation, Pagination]}
        className="mySwiper"
      >
        {sliders.map((image, index) => {
          return (
            <SwiperSlide key={index}>
              <Box
                component="img"
                sx={
                  {
                    // height: "100%",
                    // width: "100%",
                    // objectPosition: "top",
                    // objectFit: "cover",
                  }
                }
                alt="Banner"
                // padding="0px"
                // margin="0px"
                src={image}
              />
              {/* <img src={`../..${image.image.url}`} alt="imagen" /> */}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Stack>
  );
};

export default SlideImages;
