import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FC } from "react";

interface PracticeItemProps {
  icon: string;
  title: string;
  strong: string;
}

const PracticeItem: FC<PracticeItemProps> = ({ title, strong, icon }) => {
  return (
    <Stack
      direction="column"
      gap={{
        xs: "8px",
        sm: "2px",
        // md: "20px",
        // lg: "25px",
        // xl: "30px",
      }}
      alignItems="center"
      maxWidth={{
        xs: "140px",
        sm: "200px",
        // md: "60px",
        // lg: "50px",
        // xl: "60px",
      }}
    >
      {icon ? (
        <Box
          component="img"
          height={{
            xs: "60px",
            sm: "80px",
            // md: "60px",
            // lg: "50px",
            // xl: "60px",
          }}
          alt="logo"
          src={require(`../../assets/service_icons/${icon}.svg`)}
        />
      ) : (
        <></>
      )}
      <Typography
        color={"#fff"}
        fontStyle="italic"
        textAlign="center"
        fontSize={{
          xs: "13px",
          sm: "16px",
          md: "16px",
          lg: "17px",
          // xl: "22px",
        }}
      >
        {title}
        <strong>{strong}</strong>
      </Typography>
    </Stack>
  );
};

export default PracticeItem;
