import styles from "./Servicios.module.css";
import { EmployeeCard, MainTitle } from "../../componets";
import PracticeItem from "../../componets/PracticeItem/PracticeItem.component";
import { Stack, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import TomasImg from "../../assets/employees/tomas.png";
import JoseImg from "../../assets/employees/jose.png";
import DanielaImg from "../../assets/employees/daniela.png";
import SlideImages from "../../componets/SlideImagesServices/SlideImagesServices.component";

const Servicios = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const services = [
    {
      title: "Derecho de ",
      strong: "Familia y Sucesorio",
      icon: "sucesorio",
    },
    {
      title: "Derecho ",
      strong: "Informático",
      icon: "informatico",
    },

    {
      title: "Asesoramiento Jurídico de ",
      strong: "Empresas",
      icon: "asesoramiento",
    },
    {
      title: "Derecho de ",
      strong: "Daños y Perjuicios",
      icon: "daños",
    },
    {
      title: "Derecho ",
      strong: "Societario, Concursos y Quiebras",
      icon: "societario",
    },
    {
      title: "Propiedad ",
      strong: "Industrial e Intelectual",
      icon: "intelectual",
    },

    {
      title: "Derecho ",
      strong: "Deportivo",
      icon: "deportivo",
    },

    {
      title: "Derecho ",
      strong: "Societario, Registral y Notarial",
      icon: "notarial",
    },

    {
      title: "Derecho ",
      strong: "Administrativo",
      icon: "administrativo",
    },
  ];

  return (
    <main className={styles.container}>
      <section className={styles.section}>
        <div className={styles.topBackground}>
          <article className={styles.first}>
            <Stack
              // pt="80px"
              pb={{ xs: "20px", lg: "25px" }}
              width="100%"
              alignItems="start"
            >
              <Stack width={"100%"} pb={"35px"} alignItems="center">
                <MainTitle
                  color="whiteLeft"
                  title={"Áreas  de  "}
                  strong=" PRÁCTICA"
                />
              </Stack>
            </Stack>
            <div className={styles.contentGrid}>
              <Stack mx={{ xs: "20px", md: "30px" }} className={styles.grid}>
                {services.map((service, index) => (
                  <PracticeItem
                    key={index}
                    title={service.title}
                    strong={service.strong}
                    icon={service.icon}
                  />
                ))}
              </Stack>
            </div>
          </article>
        </div>
        <Stack bgcolor={"#f0f0f0"}>
          <article className={styles.second}>
            <Stack width={"100%"} pb={"35px"} alignItems="center">
              <MainTitle color="blackRight" title="" strong="COMPROMISO" />
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              width={"100%"}
              pt={"20px"}
            >
              <Stack
                direction={{ xs: "column-reverse", md: "row" }}
                gap="20px"
                width={"100%"}
                alignContent="center"
                justifyContent="center"
              >
                <Stack
                  height={"100%"}
                  width={{ xs: "300px", md: "350px" }}
                  alignSelf={{ xs: "center", md: "start" }}
                >
                  <SlideImages />
                </Stack>

                <Stack
                  gap="25px"
                  textAlign="justify"
                  mx={{ xs: "28px", md: "30px" }}
                  pb="25px"
                  maxWidth={{ xs: "500px", md: "700px" }}
                  alignSelf={{ xs: "center", md: "flex-start" }}
                >
                  <Typography
                    lineHeight="25px"
                    fontSize={{
                      xs: "13px",
                      sm: "16px",
                    }}
                  >
                    Nos destacamos por la cálida atención personalizada y la
                    fidelidad para con los clientes. Asesoramos en temas legales
                    novedosos, cotidianos y complejos.
                  </Typography>
                  <Typography
                    lineHeight="25px"
                    fontSize={{
                      xs: "13px",
                      sm: "16px",
                    }}
                  >
                    Ofrecemos el compromiso del estudio exhaustivo del caso, la
                    experiencia y la ocurrencia para arribar a la solución legal
                    de conflictos. Hacemos foco en el respeto al Derecho, la
                    Justicia y las instituciones frente a la complejidad y
                    variedad de las exigencias de los clientes. Somos Expertos
                    en litigación.
                  </Typography>
                  <Typography
                    lineHeight="25px"
                    fontSize={{
                      xs: "13px",
                      sm: "16px",
                    }}
                  >
                    Contamos con estudios jurídicos con vasta trayectoria como
                    corresponsales en C.A.B.A., La Plata, Santa Fe, Mendoza,
                    Córdoba, Salta, Tucumán, Jujuy y Rio Negro. También nos
                    encontramos asociados con estudios prestigiosos de la ciudad
                    según la materia.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </article>
        </Stack>
        {/* / --- BOTTOM --- / */}
        <div className={styles.bottomBackground}>
          <article className={styles.third}>
            <Stack width="100%" alignItems={{ xs: "center", lg: "flex-start" }}>
              <Stack width={"100%"} pb={"35px"} alignItems="center">
                <MainTitle
                  color="yellowLeft"
                  title={"Nuestro  "}
                  strong=" EQUIPO"
                />
              </Stack>
            </Stack>
            <Stack
              gap={{ xs: "70px", lg: "100px" }}
              alignItems={{ xs: "center", lg: "flex-start" }}
              justifyContent="center"
              flexDirection={{ xs: "column", lg: "row" }}
              px="15px"
              pr={{ xs: "35px" }}
              pb={{ xs: "10px", lg: "30px" }}
            >
              <EmployeeCard
                isMobile={isMobile}
                image={TomasImg}
                name="Tomás José Costante"
                email="tjc@costantebottaroabogados.com"
                description={
                  <p>
                    Abogado <strong>Especialista en Derecho Informático</strong>
                    . Como nueva generación de abogados del equipo, con el afán
                    de modernizar y ofrecer nuevos servicios, se capacitó en
                    ramas innovadoras del derecho como el Informático y en
                    Asesoramiento Jurídico de Empresas.
                  </p>
                }
              />
              <EmployeeCard
                isMobile={isMobile}
                image={JoseImg}
                name="Dr. José Dionisio Costante"
                email="jdc@costantebottaroabogados.com"
                description={
                  <p>
                    Fue Director del Registro de la Propiedad de Rosario,
                    Secretario Letrado de la Corte Suprema de la Provincia de
                    Santa Fe, Juez de los Tribunales Provinciales de Rosario y
                    Abogado asesor y jefe del área de Daños y Perjuicios en
                    Rosario de la Fiscalía de Estado de Santa Fe.{" "}
                    <strong>
                      Actividad profesional con mas de 50 años de experiencia.
                    </strong>
                  </p>
                }
              />
              <EmployeeCard
                isMobile={isMobile}
                image={DanielaImg}
                name="Daniela Paula Bottaro"
                email="dpb@costantebottaroabogados.com"
                description={
                  <p>
                    Abogada y Mediadora. Primera en incorporarse al Staff,{" "}
                    <strong>
                      desde sus comienzos sobresalió en cuestiones técnicas de
                      Derecho Procesal.
                    </strong>{" "}
                    Actualmente interviene, destacándose y con renombre en el
                    Foro de la ciudad de Rosario, en disputas de Derecho de
                    Familia y Sucesorio.
                  </p>
                }
              />
            </Stack>
          </article>
        </div>
      </section>
    </main>
  );
};

export default Servicios;
