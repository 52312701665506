import { combineReducers, configureStore } from "@reduxjs/toolkit";
import dataReducer from "./slices/business.slice";
import authAdminReducer from "./slices/authAdmin.slice";
import slidersReducer from "./slices/sliders.slice";


const rootReducer = combineReducers({
  data: dataReducer,
  authAdmin: authAdminReducer,
  sliders: slidersReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
