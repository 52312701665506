import { Box, TextField } from "@mui/material";
import { FC } from "react";
import { useController, useFormContext } from "react-hook-form";

type ControlledTextInputProps = {
  name: string;
  label: string;
  defaultValue?: string;
  disabled?: boolean;
  variant?: "filled" | "outlined" | "standard";
};

const ControlledTextInput: FC<ControlledTextInputProps> = ({
  name,
  label,
  defaultValue,
  disabled,
  variant,
}: ControlledTextInputProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value, ref },
    formState: { errors },
  } = useController<Record<string, string>>({
    name: name,
    control,
    defaultValue,
  });

  return (
    <Box mb={2}>
      <TextField
        onChange={onChange}
        sx={{
          height: "45px",

          "& .MuiFormHelperText-root": {
            textAlign: "right",
          },
        }}
        value={value}
        label={label}
        size="small"
        inputRef={ref}
        variant={variant ? variant : "outlined"}
        fullWidth
        error={!!errors[name]}
        helperText={`${errors[name]?.message || ""}`}
        disabled={disabled}
      />
    </Box>
  );
};

export default ControlledTextInput;
