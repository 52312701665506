import { FC } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Email from "../../assets/mailVerde.svg";

interface EmployeeCardProps {
  image: string;
  isMobile: boolean;
  name: string;
  description: JSX.Element;
  email: string;
}

const EmployeeCard: FC<EmployeeCardProps> = ({
  image,
  isMobile,
  name,
  description,
  email,
}) => {
  return (
    <Stack
      direction="column"
      gap={{ xs: "15px", sm: "18px" }}
      color={"#fff"}
      height="100%"
      maxWidth={{ xs: "290px" }}
      minHeight="220px"
    >
      {image ? (
        <Box
          justifySelf="center"
          component="img"
          alignSelf="center"
          sx={{
            width: "280px",
          }}
          alt="image"
          src={image}
        />
      ) : (
        <></>
      )}
      <Typography
        align="center"
        justifySelf="center"
        variant="body1"
        textAlign="center"
        fontSize={{
          xs: "13px",
          sm: "14px",
        }}
        lineHeight={{
          xs: "20px",
          sm: "22px",
        }}
        fontWeight={200}
        fontStyle="italic"
      >
        {description}
      </Typography>
    </Stack>
  );
};

export default EmployeeCard;
