import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getDataFromApi } from "../../store/slices/business.slice";
import styles from "./Map.module.css";

const MapComponent = () => {
  const dispatch = useAppDispatch();

  const { data } = useAppSelector((state) => state.data);

  useEffect(() => {
    if (data.latitude === 0) {
      dispatch(getDataFromApi());
    }
  }, [data.latitude, dispatch]);
  // 60.70435525549583!3d-32.94717416180316
  // https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3347.75413995533!2d${data.longitude}!3d${data.latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b7ab6225ac483d%3A0x11f76d064ab782b!2sGrupo%20R%20%26%20F!5e0!3m2!1sen!2sar!4v1665496391614!5m2!1sen!2sar

  return (
    <div className={styles.container}>
      <iframe
        src={`
                https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3348.1445182180014!2d-60.63689002354426!3d-32.9471936735929!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b7ab5ee90654c5%3A0x10bb6ef68550f8be!2sCostante%20%26%20Bottaro%20Abogados!5e0!3m2!1sen!2sar!4v1690752345019!5m2!1sen!2sar
                `}
        width="100%"
        height="100%"
        // style="border:0;"
        // allowfullscreen=""
        title="costantebottaro"
        loading="lazy"
        style={{ border: "none", margin: "0px", padding: "0px" }}
        // referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default MapComponent;
