import { useEffect } from "react";
import { LoadSlice } from "../../../componets/Admin/LoadSlice/LoadSlice.component";
import withAuth from "../../../HOC/withAuth.hoc";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getSlidersApi } from "../../../store/slices/sliders.slice";
import styles from "./SlidesSection.module.css";

const SlidesSection = () => {
  const token = useAppSelector((state) => state.authAdmin.token);
  const sliders = useAppSelector((state) => state.sliders.sliders);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSlidersApi());
  }, []);

  return (
    <section className={styles.section}>
      <h3 className={styles.title}>SLIDES</h3>
      <div className={styles.slidesSection}>
        <LoadSlice tag="mobile" images={sliders.mobile} />
        <LoadSlice tag="tablet" images={sliders.tablet} />
        <LoadSlice tag="desktop" images={sliders.desktop} />
      </div>
    </section>
  );
};

export default withAuth(SlidesSection);
