import styles from "./HeaderDesktop.module.css";
import Logo from "../../../assets/logo.svg";
import LogoBlanco from "../../../assets/logoBlanco.svg";
import NavLinks from "../../NavLinks/NavLinks.component";
import { useLocation, useNavigate } from "react-router-dom";
import { useMemo } from "react";

export const HeaderDesktop = () => {
  let navigate = useNavigate();

  const location = useLocation();
  const currentRoute = location.pathname;

  const headerStyle = useMemo(() => {
    if (currentRoute === "/") {
      return styles.contentBlack;
    }
    if (currentRoute === "/servicios") {
      return styles.contentGreen;
    } else {
      return styles.contentBlack;
    }
  }, [currentRoute]);

  return (
    <header className={styles.container}>
      <div className={headerStyle}>
        <img
          style={{
            visibility: currentRoute === "/" ? "hidden" : "visible",
          }}
          src={headerStyle == styles.contentBlack ? LogoBlanco : Logo}
          alt="logo"
          className={styles.logo}
          onClick={() => navigate("/")}
        />

        <div className={styles.containerLinks}>
          <NavLinks />
        </div>
      </div>
      <div className={styles.header}></div>
    </header>
  );
};
