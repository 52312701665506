import styles from "./Footer.module.css";
import Logo from "../../assets/logoBlanco.svg";
import LogoMobile from "../../assets/logoBlancoSimple.svg";
import IconLinkedin from "../../assets/linkedin.svg";
import IconInstagram from "../../assets/instagram.svg";
import IconMail from "../../assets/mail.svg";
import { ReactComponent as IconMap } from "../../assets/positionVerde.svg";
import { ReactComponent as IconTelephone } from "../../assets/TelefonoVerde.svg";

import { useAppSelector } from "../../store/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { useMemo } from "react";

const Footer = () => {
  let navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const currentRoute = location.pathname;

  const { data } = useAppSelector((state) => state.data);

  const footerStyle = useMemo(() => {
    if (currentRoute === "/") {
      return styles.containerBlack;
    }
    if (currentRoute === "/servicios") {
      return styles.containerGreen;
    } else {
      return styles.containerGrey;
    }
  }, [currentRoute]);

  return (
    <footer className={footerStyle}>
      <div className={styles.firstColumn}>
        <Box
          onClick={() => navigate("/")}
          component="img"
          sx={{
            width: { xs: "70px", sm: "140px" },
          }}
          pr={{ xs: "15px", sm: "30px" }}
          alt="logo"
          src={isMobile ? LogoMobile : Logo}
        />

        <div className={styles.dataContainer}>
          <Stack gap="6px" className={styles.dataContact}>
            <a
              className={styles.icon}
              href={`https://api.whatsapp.com/send/?phone=${data?.telephones[0]?.number}`}
              target="_blanck"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", width: "max-content" }}
            >
              <Stack
                direction="row"
                gap="10px"
                width="fit-content"
                alignItems="center"
                color={"#fff"}
              >
                <IconTelephone />
                <Typography
                  fontSize={{
                    xs: "10px",
                    sm: "14px",
                  }}
                >
                  <span> +54 {data?.telephones[0]?.number}</span>
                </Typography>
              </Stack>
            </a>

            <a
              className={styles.icon}
              href={`https://www.google.com/maps?ll=${data.latitude},${data.longitude}&z=16&t=m&hl=en&gl=AR&mapclient=embed&cid=1205679330329426110`}
              target="_blanck"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", width: "max-content" }}
            >
              <Stack
                direction="row"
                gap="10px"
                width="fit-content"
                alignItems="center"
                color={"#fff"}
              >
                <IconMap />
                <Typography
                  fontSize={{
                    xs: "10px",
                    sm: "14px",
                  }}
                >
                  <span className={styles.prespacer}>{data?.street}</span>
                  <span className={styles.spacer}>
                    <span>Rosario, Santa Fe</span>
                  </span>
                </Typography>
              </Stack>
            </a>

            {data?.email ? (
              <Stack
                direction="row"
                gap="10px"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/contacto")}
                width="fit-content"
                alignItems="center"
              >
                <img style={{ width: "20px" }} src={IconMail} alt="mail" />
                <Typography
                  fontSize={{
                    xs: "10px",
                    sm: "14px",
                  }}
                >
                  {data?.email}
                </Typography>
              </Stack>
            ) : (
              <></>
            )}
            {data?.instagram ? (
              <a
                className={styles.icon}
                href={`https://ar.linkedin.com/company/costante-bottaro-abogados?trk=public_profile_topcard-current-company`}
                target="_blanck"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", width: "max-content" }}
              >
                <Stack
                  direction="row"
                  gap="10px"
                  width="fit-content"
                  alignItems="center"
                  color={"#fff"}
                >
                  <img src={IconLinkedin} alt="linkedin" />

                  <Typography
                    width="fit-content"
                    fontSize={{
                      xs: "10px",
                      sm: "14px",
                    }}
                  >
                    {data?.linkedin}
                  </Typography>
                </Stack>
              </a>
            ) : (
              <></>
            )}
            {data?.instagram ? (
              <a
                className={styles.icon}
                href={`https://www.instagram.com/${data?.instagram}`}
                target="_blanck"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", width: "max-content" }}
              >
                <Stack
                  direction="row"
                  gap="10px"
                  width="fit-content"
                  alignItems="center"
                  color={"#fff"}
                >
                  <img src={IconInstagram} alt="instagram" />

                  <Typography
                    width="fit-content"
                    fontSize={{
                      xs: "10px",
                      sm: "14px",
                    }}
                  >
                    {data?.instagram}
                  </Typography>
                </Stack>
              </a>
            ) : (
              <></>
            )}
          </Stack>
        </div>
      </div>
      <div className={styles.secondColumn}>
        <div className={styles.rightsAndDevleoper}>
          <p>Todos los derechos reservados desde 2023</p>
          <p>
            Diseño del sitio:
            <span>
              <a
                className={styles.dgweb}
                href="https://dgwebrosario.com.ar/"
                target="_blanck"
                rel="noopener noreferrer"
              >
                DgWeb.Rosario
              </a>
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
