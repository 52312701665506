import { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import styles from "./NavLinksAdmin.module.css";

type Prop = {
  color?: string;
};
const NavLinksAdmin: FC<Prop> = ({ color }) => {
  const location = useLocation();
  const currentRoute = location.pathname;

  const role = useAppSelector((state) => state.authAdmin.role);

  return (
    <nav
      className={color === "white" ? styles.containerWhite : styles.container}
    >
      <ul>
        <li className={currentRoute === "/administracion" ? styles.active : ""}>
          <NavLink to="/administracion">ADMIN</NavLink>
        </li>

        <li
          className={
            currentRoute === "/administracion/datos" ? styles.active : ""
          }
        >
          <NavLink to="datos">DATOS</NavLink>
        </li>
        {role === "root" && (
          <li
            className={
              currentRoute === "/administracion/sliders" ? styles.active : ""
            }
          >
            <NavLink to="sliders">SLIDERS</NavLink>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default NavLinksAdmin;
